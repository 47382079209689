<template>
  <div class="about">
    <div class="main-section">
      <h1>Welcome to Tulip!</h1>
      <div class="content-text">
        <h2>What is Tulip?</h2>
        <p>Tulip is a decentralized, non-custodial stock exchange and liquidity market protocol.  Publicly regulated companies and third parties can list tokenized stocks, bonds and exchange-traded funds (ETFs) on Tulip where users in turn can participate in the buying, selling and trading of said tokenized stocks, bonds and exchange-traded funds (ETFs).</p>

        <p>In addition, Tulip provides a liquidity marketplace for users to provide liquidity for various crypto assets, including stocks, bonds and ETFs, allowing anyone to trade, as well as lend out and borrow said assets.</p>
        <p>All stocks, bonds and ETFs are tokenized versions on the blockchain that represent as proof of partial ownership of an Issuer, an Issuer’s debtholders or creditors, and owner of a particular ETF respectively.</p>

        <p>Although similar to a traditional stock exchange, Tulip differs in significant ways.  Firstly, the financial instruments that companies can list on the platform are converted into a type of non-fungible token, or NFT.  Secondly, Tulip is not privately owned, nor is it managed by a few individuals.  It will in fact be maintained and governed by the Tulip community via consensus; a variety of stakeholders who hold the Protocol’s native governing token, BULB, in order to vote on policies and development.
        Thirdly, the Tulip Protocol does not reside in a particular nation or regulatory jurisdiction and thus, it is not subject to one particular set of laws and regulations.  It is essentially a ‘universal’ platform. As such, when it comes to allowing companies/businesses and individuals to use it, the Protocol itself has little to no requirements or conditions.  <strong>However, this does not suggest that the Protocol is not without legal and ethical responsibilities.</strong>
        </p>
    </div>

    <div class="content-text">
      <h2>What does Tulip seek to achieve?</h2>
      <p>Tulip seeks to be an answer to the dilapidating situation of many governments’ inability to effectively regulate a financial system that has largely continued to function, via many unethical means, without regard to the well-being of their local economies and citizenries.  We only need to look at the reactions and responses of governments to the Financial crisis of 2007-2008 and the 2021 GameStop Short Squeeze to get a glimpse of the extent of the damage.</p>

      <p>The ills that have hampered the financial system are several folds.  Firstly, a highly centralized structure has allowed much of the decision-making power and wealth to be in the hands of a privileged few individuals and corporations, barring most people from participating.  The business relationship between governments and the financial system has created a series of conflicts of interests to arise, postulating whether government institutions are there to protect investors and their citizens.  Thirdly, a lack of total transparency has allowed the financial system as a whole to make repeated illicit offensives behind closed doors.  This in turn has created a  culture where parties are unwilling to take responsibility.  Lastly, the highly restrictive, sometimes conflicting rules and regulations of stock trading, especially internationally, have barred small to medium time users from participating.  All of this has resulted in much of society losing faith in their governments for regulating, even protecting against an ever increasing wild, manipulative financial system that is continuously being seen as a reservation for only a privileged elite.
      </p>
      <p>
        By using the concept of decentralization, the code for smart contracts, the power of community governance and consensus and blockchain technology, Tulip is being built to aid in the process of taking down many of the barriers and creating a much more inclusive, impartial, transparent, responsible financial system.
      </p>
  </div>

    </div>
  </div>
</template>
<script>
export default {
  name: 'About',
  components: {
  },
  data(){
    return{
    }
  },
  methods:{
  },
}
</script>
<style scoped>
.about{
  width: 100%;
  height: 100%;
  min-height: 100vh;
  color: #fff;
}
.main-section{
  width: 80%;
  margin: 3rem auto;
}
.content-text{
  text-align: left;
}
.content-text p{
  margin: 1rem .2rem;
  margin-left: 2rem;
}
</style>
